import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import store from './store'
import VueNumberFormat from 'vue-number-format'
import vueAwesomeCountdown from 'vue-awesome-countdown'

Vue.use(vueAwesomeCountdown, 'countdown');
Vue.use(VueNumberFormat, {prefix: '', decimal: '.', thousand: ',', precision: 4});

Vue.config.productionTip = false;

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
