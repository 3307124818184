<template>
<div>
  <div class="logo">
    <img src="./assets/logo.png">
    <el-progress :show-text="false" :stroke-width="22" :percentage="parseInt((numMinted / maxMint) * 100)" style="margin-top: 15px; margin-bottom: 8px; width: 350px; margin-left: auto; margin-right: auto;"></el-progress>
    {{numMinted}} / {{maxMint}} minted
    <div class="links">
      <button plain v-if="checkWalletConnected() && wallet.connected === false" @click="connectWallet">sync</button>
      <button plain v-else @click="disconnectWallet">unsync</button>
    </div>
  </div>

  <div id="app">
      <div class="mint-menu" v-loading="mintOpPending" element-loading-background="rgba(0, 0, 0, 0.6)">
        <button class="mint-1" @click="mint(1)">?</button>
        <button class="mint-3" @click="mint(3)">?</button>
        <button class="mint-5" @click="mint(5)">?</button>
      </div>
  </div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getWalletContract } from './utils/tezos';
import tzkt from './utils/tzkt';

export default {
  name: 'app',
  data() {
    return {
      discordUrl: process.env.VUE_APP_DISCORD_URL,
      twitterUrl: process.env.VUE_APP_TWITTER_URL,
      objktUrl: process.env.VUE_APP_OBJKT_URL,
      crowdSaleContract: process.env.VUE_APP_CROWDSALE_CONTRACT,
      fa2Contract: process.env.VUE_APP_FA2_CONTRACT,
      provenanceHash: process.env.VUE_APP_PROVENANCE_HASH,
      saleStartTime: new Date('2022-02-10T15:00:00Z').getTime(),
      numMinted: 0,
      maxMint: 1111,
      mintOpPending: false,
    }
  },
  computed: {
    ...mapState([
      'wallet'
    ]),

    isMinting: function() {
      return (new Date() >= this.saleStartTime);
    }
  },
  created() {
    this.updateMinted();
  },
  methods: {
    ...mapActions([
      'checkWalletConnected',
      'connectWallet',
      'disconnectWallet',
      'changeWallet'
    ]),

    async updateMinted() {
      const storage = await tzkt.getContractStorage(this.crowdSaleContract);
      this.numMinted = parseInt(storage.data.sale.soldAmount);
      if (this.numMinted < this.maxMint) {
        const vm = this;
        setTimeout(vm.updateMinted, 15000);
      }
    },

    async mint(mintNum) {
      const amount = parseInt(mintNum) * 11000000;
      if (amount < 1) {
          return;
      }

      try {
        await this.connectWallet();
        const op = await (await getWalletContract(process.env.VUE_APP_CROWDSALE_CONTRACT))
          .methods
          .mint(mintNum)
          .send({
            amount: amount,
            mutez: true,
            storageLimit: 3000
          });
        this.mintOpPending = true;
        await op.confirmation();
      } catch (e) {
        console.log(e);
      }

      this.mintOpPending = false;
    }
  }
}
</script>

<style scss>

html,
body {
  margin: 0;
  height: 100%;
}

body {
  font-family: 'DM Sans', sans-serif;
  background: #8088C2 url(./assets/bg.png);
}

#app {
  position: absolute;
  top: 175px;
  bottom: 50px;
  left: 50px;
  right: 50px;
  background: url(./assets/oven.png) bottom center no-repeat;
  background-size: contain;
}

.mint-menu {
   position: absolute;
   top: 0px;
   right: 33%;
   width: 397px;
   height: 500px;
   background: url(./assets/mint-menu.png) center center no-repeat;
}
.mint-menu button {
  position: absolute;
  background: url(./assets/taco-button-question.png) center center no-repeat;
  text-indent: -999em;
  overflow: hidden;
  border: none;
  padding: 0;
  margin: 0;
  width: 135px;
  height: 80px;
}
.mint-menu button.mint-1 {
  top: 155px;
  left: 29px;
}
.mint-menu button.mint-3 {
  top: 265px;
  left: 23px;
}
.mint-menu button.mint-5 {
  top: 377px;
  left: 23px;
}
.logo {
  background: #000;
  color: #fff;
  padding: 15px;
  text-align: center;
}
.logo img {
  height: 50px;
}

.links {
  position: absolute;
  top: 15px;
  right: 15px;
}

.links button {
  color: #000;
   background: url(./assets/taco-button-plain.png) center center no-repeat;
   background-size: contain;
   border: none;
   padding: 22px 0px 8px 5px;
   width: 110px;
   font-weight: bold;
}

button:hover {
  cursor: pointer;
}

@media only screen and (max-width: 991px) {
  .mint-menu {
    right: 15%;
  }
}

</style>
